.ql-snow.ql-toolbar {
  display: block;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100%;
}

.ql-bubble.ql-container {
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
}

.ql-snow.ql-container {
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
}

.ql.bubble>.ql-editor {
  overflow-y: scroll;
  max-height: 700px;
}

.ql.bubble>.ql-editor .ql-size-small {
  font-size: 16px;
}

.ql.bubble>.ql-editor .ql-size-large {
  font-size: 18px;
}

.ql.bubble>.ql-editor .ql-size-huge {
  font-size: 22px;
}

.ql-snow>.ql-editor {
  min-height: 400px;
  overflow-y: scroll;
  resize: vertical;
}

.ql-snow>.ql-editor .ql-size-small {
  font-size: 16px;
}

.ql-snow>.ql-editor .ql-size-large {
  font-size: 18px;
}

.ql-snow>.ql-editor .ql-size-huge {
  font-size: 22px;
}

.quill * {
  /* font-size: initial; */
}

.ql-editor .ql-size-small {
  font-size: 16px;
}

.ql-editor .ql-size-large {
  font-size: 18px;
}

.ql-editor .ql-size-huge {
  font-size: 22px;
}