@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 600;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 800;
  font-weight: 900;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
  font-size: 1.25rem;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.small-text {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.normal-text {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.large-text {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.xlarge-text {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}